import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS, SIZES } from '../styles/theme'

import { Row, Col } from 'react-bootstrap'
import eventImg from '../images/programImage.jpg'
import logo from '../images/logo.png'
import senhengLogo from '../images/SenhengLogo.png'

const Page = styled.div`
  background-image: linear-gradient(180deg, #ffffff, #E5E5E5);
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  #header {
    background-image: linear-gradient( 180deg, #0269EA, #00469D, #00469D );
    padding-top: 3vh;
    h3 {
      color: white;
      font-style: italic;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 1.5rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }
  .col-md-3 {
    padding: 16px 0;
    align-self: center;
    strong {
      color: #00469D;
      display: block;
      font-size: 0.75em;
    }
    #senheng {
      mix-blend-mode: multiply;
    }
  }
`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    const difference = +new Date(2020, 10, 27, 16, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })

  return (
    <Page>
      <div id="header">
        <h3 className="mt-3 my-md-5">PACMY & SENHENG Business Transformation Workshop</h3>
        <div className="time-wrapper">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </div>
      <Row className="px-4" noGutters>
        <Col md={{ span:3, offset: 3}}>
          <img src={logo} width="50%" alt="panasonic" />
        </Col>
        <Col md={{ span:6, offset: 3, order:'last' }}>
          <img src={eventImg} width="100%" alt="event" />
        </Col>
        <Col md={3}>
          <strong>In Collaboration with</strong>
          <img id="senheng" src={senhengLogo} width="50%" alt="senheng" />
        </Col>
      </Row>
    </Page>
  )
}
