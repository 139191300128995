const TOGGLE_MUTE = 'TOGGLE_MUTE'
const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM'
const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL'
const HIDE_GLOBAL_MODAL = 'HIDE_GLOBAL_MODAL'

const initialState = {
  showForm: false,
  muted: false,
  globalModal: null,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      }

    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        showForm: !state.showForm,
      }

    case SHOW_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: action.globalModal,
      }

    case HIDE_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: null,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}

export function toggleShowForm() {
  return { type: TOGGLE_QUESTION_FORM }
}

export function showGlobalModal(globalModal) {
  return { type: SHOW_GLOBAL_MODAL, globalModal: globalModal }
}