import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import config from '../../config';
import routes from '../../routes';
import { SIZES } from '../../styles/theme';
import logo from '../../images/logo.png'
import mobileProgramImage from '../../images/mobileProgram.png'
import BgImg from '../../images/backdrops/entrance.jpg'

const Page = styled.div`

  min-height: 100vh;
  max-width: 100vw;
  font-style: italic;
  text-align: center;
  padding: 2em;
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;
  position: relative;

  ${SIZES.mobile} {
    font-size: 75%;
  }
  .image img {
    width: 60%;
    ${SIZES.mobile} {
      width: 80%;
    }
  }
  header {
    color: #0269EA;
    font-size: 1.2em;
    ${SIZES.mobile} {
      img {
        width: 50%;
      }
    }
  }
  a,
  button {
    font-size: 1.2em;
    font-style: italic ;
    width: 250px;
    display: flex;
    justify-content: space-between;
    background: #0269EA;
    color: #F6F6F9;
    border : 2px solid #00469D;
    border-radius: 56px;
    margin: 0 auto;
    margin-top: auto;
    margin-bottom: 0;
    padding: 8px 24px;

    &:hover {
      color: #F6F6F9;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
  .underlay {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export default function PreRegisterPage() {

  useEffect(() => {
    ReactGA.pageview('/pre-register-page')
  }, [])

  return (
    <Page>
      <div className="underlay">
        <img id="lg-bg" className="d-none d-lg-block" src={config.assets.entrance.landscape} alt="landscape-bg" />
        <img className="d-md-block d-lg-none" src={BgImg} alt="landscape-bg" />
      </div>
      <header>
        <img src={logo} className="mb-3 mb-md-5" width="25%" alt="panasonic"/>
        <h3 className="font-weight-bold">Welcome</h3>
        <p>Kindly RSVP by sliding below bar to confirm your attendance by 
          <strong> 19 November 2020</strong>
        </p>
      </header>
      <div className="image d-lg-none">
        <img src={mobileProgramImage} alt="event"/>
      </div>
      <Button variant="default" as={Link} to={routes.register}>
        <div>RSVP</div>
        <div className="arrow">⇀</div>
      </Button>
    </Page>
  )
}