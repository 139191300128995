import React from 'react'
import { Modal } from 'react-bootstrap'
import agenda from '../../images/Schedule.jpg'

export default function ProgrammeModal({ showModal, hideSchedule }) {
  return (
    <Modal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="schedule-modal"
      centered
    >
      <Modal.Body>
        <img src={agenda} alt="event agenda" width="100%" />
      </Modal.Body>
    </Modal>
  )
}
