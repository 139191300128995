import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import { SIZES } from '../../styles/theme'

import { Row, Col } from 'react-bootstrap'
import BgImg from '../../images/backdrops/entrance.jpg'
import MobileImg from '../../images/backdrops/entranceMobile.jpg'
import logo from '../../images/logo.png'
import senhengLogo from '../../images/SenhengLogo.png'
import eventImg from '../../images/programImage.jpg'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${BgImg});
  background-repeat: no-repeat;
  background-size: cover;
  color: #0269EA;
  padding: 2em;
  text-align: center;

  ${SIZES.mobile} {
    background: url(${MobileImg});
    background-size: 100%;
    font-size: 1.1em;
    header img {
      width: 65%;
    }
  }
  header {
    padding: 0 1.5em;
    h4 {
      font-weight: 400;
      font-size: 1.25em;
      letter-spacing: 1px;
      margin-bottom: 1em;
    }
    strong {
      font-size: 0.75em;
    }
    #senheng {
      mix-blend-mode: multiply;
    }
  }
  strong {
    display: block;
    margin: 0.75em auto;
    color: #00469D;
  }
`

export default function PostRegisterPage() {
  useEffect(() => {
    ReactGA.pageview('/post-register-page')
  }, [])

  return (
    <Page>
      <Row>
        <Col md={{ span:6, offset:3}}>
          <header>
            <img src={logo} className="mb-5" width="40%" alt="panasonic"/>
            <h4>
              <em>Thank you for RSVP to PACMY New Generation Development Program 1st Workshop Activity</em>
            </h4>
            <p>You will be receiving an email confirmation shortly</p>
            <div className="mt-5 mb-3">
              <strong>In Collaboration with</strong>
              <img id="senheng" src={senhengLogo} width="40%" alt="senheng" />
            </div>
          </header>
          <div>
            <img src={eventImg} width="100%" alt="event" />
          </div>
          <p>
            <strong>
              For further enquiries, please refer to your respective sales in charge
            </strong>
          </p>
        </Col>
      </Row>
    </Page>
  )
}
