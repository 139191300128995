import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { SIZES } from '../../styles/theme'

import logo from '../../images/logo.png'
import BgImg from '../../images/backdrops/entrance.jpg'
import MobileImg from '../../images/backdrops/entranceMobile.jpg'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background: url(${BgImg});
  background-repeat: no-repeat;
  background-size: cover;
  color: #0269EA;
  padding: 2.5em 0;
  font-style: italic;
  text-align: center;

  ${SIZES.mobile} {
    background: url(${MobileImg});
    background-size: 100%;
    padding: 0;
    padding-bottom: 2.5em;
    font-size: 80%;
  }
  header {
    h3 {
      font-weight: 600;
      font-size: 1.75em;
    }
    p {
      font-size: 1.2em;
      margin-bottom: 0;
    }
  }
  .row {
    .register-form {
      padding: 2.5em;

      .form-group {
        margin: 12px auto;
        width: 100%;
        border: 1px solid #0269EA;
        background: #F8F4FF;
        border-radius: 30px;
        font-weight: 600;

        label {
          color: #00469D;
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .form-input {
          display: block;
          color: #2F2651;
          letter-spacing: 1px;
          width: 100%;
          background: transparent;
          border: none;
          padding: 4px 24px;
          border-radius: 25px;
          outline: none;
        }
      }
    }
  }
  a,
  button.submit-btn {
    background: #0269EA;
    width: 100%;
    color: #F6F6F9;
    font-weight: 500;
    font-style: italic;
    border: 1px solid #00469D;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    margin: 0 auto;
    font-size: 0.8em;
    outline: none;
    box-shadow: none;
    ${SIZES.mobile} {
      width: 100%;
      padding: 4px 24px;
    }
    &:disabled {
      cursor: not-allowed;
      background: #c3c3c3;
      border: solid 1px #707070;
    }
    :not(:disabled):hover {
      color: #F6F6F9;
    }
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function ResetPasswordPage() {
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(
      `${config.apiUrl}/api/v1/create_password`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${queryString.parse(location.search).token}`,
        },
      }
    )
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.login)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    password.length &&
    passwordConfirmation.length &&
    password === passwordConfirmation
  )

  return (
    <Page>
      <header className="px-5 pt-5 pb-3 py-md-0">
        <img src={logo} className="d-none d-md-block mb-3 mx-auto" width="20%" alt="panasonic" />
        <h3>PACMY & SENHENG Business Transformation Workshop</h3>
        <p>
          Date: 27th November 2020<br/>
          Time: 4pm - 6pm
        </p>
      </header>
      <Row noGutters>
        <Col md={{span:4,offset:4}}>
          <Form onSubmit={handleSubmit}>
            <Row className="register-form py-3" noGutters>
              <Form.Group>
                <label>Password</label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Password Confirmation</label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </Form.Group>
              <Col md={12} className="w-100 mt-3">
                {hasEmptyField && (
                  <p className="text-danger text-right">
                    <em>
                      <small>Please make sure both password matches</small>
                    </em>
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="default"
                  disabled={hasEmptyField || loading}
                  onSubmit={handleSubmit}
                >
                  <div>{loading ? 'Loading...' : 'CREATE PASSWORD'}</div>
                  <div className="arrow">⇀</div>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}