import React from 'react'
import { Modal } from 'react-bootstrap'
import SpeakerBio from '../../images/SpeakerIntro.jpg'

export default function SpeakersModal({ showModal, hideSchedule }) {
  return (
    <Modal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="speakers-bio"
      centered
    >
      <Modal.Body>
        <img src={SpeakerBio} alt="speaker info" width="100%"/>
      </Modal.Body>
    </Modal>
  )
}
