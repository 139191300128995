import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import logo from '../images/logo.png'
import WelcomePage from '../pages/WelcomePage'
import LoginPage from '../pages/LoginPage'
import config from '../config'
import { SIZES } from '../styles/theme'
import routes from '../routes'
import BgImage from '../images/backdrops/entrance.jpg'

const Page = styled.div`
  background: url(${BgImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  ${SIZES.mobile} {
    background: url(${config.assets.entrance.portrait});

    .content {
      img {
        width: 50%;
      }
    }
  }
  .content {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 1em;
  }
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <div className="content">
        <img src={logo} alt="panasonic" width="25%" />
      </div>
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Page>
  )
}
