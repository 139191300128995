import Logo from './images/logo.png'
import AuditoriumL from './images/backdrops/auditoriumPC.jpg'
import AuditoriumM from './images/backdrops/auditoriumMobile.jpg'
import Entrance from './images/backdrops/backgroundLandscape.jpg'
import EntranceM from './images/backdrops/entranceMobile.jpg'
import LobbyL from './images/backdrops/lobbyPC_r1.jpg'
import LobbyM from './images/backdrops/lobbyMobile_r1.jpg'

import LobbyMusic from './images/audio/lobby.mp3'
import { SIZES } from './styles/theme'
import routes from './routes'

// make sure no trailing slash
const dev = {
  apiUrl: 'http://localhost:3000',
}

// make sure no trailing slash
const prod = {
  apiUrl: 'https://pacmyvirtual.herokuapp.com',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

// if there is no portrait asset, just use landscape asset as portrait property value
const assets = {
  lobbyMusic: LobbyMusic,
  countdown: null,
  booth: null,
  logo: {
    landscape: Logo,
    portrait: Logo,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: LobbyM,
  },
  auditorium: {
    landscape: AuditoriumL,
    portrait: AuditoriumM,
  },
  exhibitionHall: {
    landscape: null,
    portrait: null,
  },
}

const layout = {
  auditoriumVid: `
    top: 9.4%;
    bottom: 28.8%;
    left: 22.3%;
    right: 22.5%;
    ${SIZES.mobile} {
      top: 16.2%;
      bottom: 52%;
      left: 7.1%;
      right: 7.5%;
    }
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    // pinText: 'Enter Auditorium',
    // overlayText: '',
    destination: routes.auditorium1,
    css: `
      top: 50.9%;
      left: 56.4%;
      right: 32.4%;
      bottom: 35.9%;
      ${SIZES.mobile} {
        top: 49.5%;
        left: 64%;
        right: 18%;
        bottom: 44.5%;
      }
    `,
  },
]

export default {
  assets,
  layout,
  lobbyEntries,

  eventName: 'PACMY & SENHENG Business Transformation Workshop',
  googleAnalyticsID: 'UA-180732110-4',
  gsheetQuestionApi:
    'https://script.google.com/macros/s/AKfycbzWqe4JgaKmJ2ukTwG0V-vp5TBCg2VaPeTIhQTWjRDCaUogdzI/exec',

  ...config,
}
