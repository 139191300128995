import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { COLORS, SIZES } from '../styles/theme'
import { Button } from 'react-bootstrap'
import logo from '../images/logo.png'
import mobileProgramImage from '../images/mobileProgram.png'
import BgImg from '../images/backdrops/entrance.jpg'

const Page = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  color: ${COLORS.primary};
  padding: 2em;
  padding-bottom: 3em;
  font-style: italic;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;

  ${SIZES.mobile} {
    header {
      h3 {
        font-size: 1.25em;
      }
      img {
        width: 50%;
      }
    }
  }
  .image img {
    width: 60%;
    ${SIZES.mobile} {
      width: 80%;
    }
  }
  a,
  button {
    background: ${COLORS.primary};
    width: 250px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: auto;
    font-size: 1.1em;
    color: #F6F6F9;
    font-weight: 500;
    padding: 10px 35px;
    border-radius: 56px;
    font-style: italic ;

    &:hover {
      color: #F6F6F9;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
  .underlay {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <div className="underlay">
        <img id="lg-bg" className="d-none d-lg-block" src={config.assets.entrance.landscape} alt="landscape-bg" />
        <img className="d-md-block d-lg-none" src={BgImg} alt="landscape-bg" />
      </div>
      <header>
        <img src={logo} className="mb-3 mb-md-5" width="25%" alt="panasonic"/>
        <h3 className="font-weight-bold my-4">
          WELCOME TO PACMY & SENHENG Business Transformation Workshop
        </h3>
      </header>
      <div className="image d-lg-none">
        <img src={mobileProgramImage} width="100%" alt="event"/>
      </div>
      <Button variant="default" as={Link} to={routes.login}>
        Login
        <div className="arrow">⇀</div>
      </Button>
    </Page>
  )
}
